import * as actionTypes from "./actionTypes"
import axios from 'axios';
import { userService } from "../../services/userServices";
import { userConstants } from '../actions/actionTypes';
import {alertActions} from '../../redux/actions/alertActions'

export const userActions = {
  login,
  logout,
  register,
  changeSelectedCard,
  getCreditCards
};

function login(username, password,history, from) {
  return dispatch => {
      dispatch(request({ username }));

      userService.login(username, password)
          .then(
              user => { 
                console.log('logintest')
                console.log(user)
                  dispatch(success(user));
                  history.push(from);
              },
              error => {
                console.log('loginerrortest')
                console.log(error.response.data.error)
                  dispatch(failure(error.response.data.error.toString()));
                  dispatch(alertActions.error(error.response.data.error.toString()));
              }
          );
  };
  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return dispatch => {
      dispatch(request(user));

      userService.register(user)
          .then(
              user => { 
                  dispatch(success());
                  // history.push('/login');
                  dispatch(alertActions.success('Registration successful'));
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          );
  };

  function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
  function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

export function changeSelectedCard(card){
    return {type:actionTypes.CHANGE_SELECTED_CARD, payload:card}
}

export function getCreditCardsSuccess(categories){
    return {type:actionTypes.GET_CREDIT_CARDS_SUCCESS, payload:categories}
}

export function getCreditCards(){
    return function(dispatch){
        
        console.log('Payment Page User Token:',localStorage.getItem('loginToken'))
        axios.get('https://liveapi.appac.io/user/cards',{headers:{
          'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
        }})
        .then(function (response) {
          // handle success
          console.log(response.data);
          dispatch(getCreditCardsSuccess(response.data))
        })
    }
}

