import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap'
import axios, {AxiosResponse}  from "axios";

 function ForgotPassword() {
    const [userName, setUserName] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState(null)


    const sendEmail = (username) => {
        axios.get(`https://liveapi.appac.io/user/auth/forgot/send/${userName}`)
        .then((response) => {

                setAlertMessage({status:response.request.status, message:response.data.message})

                // history.push('./profile')
            }).catch(err => {
                console.log(err.response.data.error);
                setAlertMessage({status:err.response.request.status, message:err.response.data.error})

            })
    }

    return (
        <>
            <div className="row justify-content-center mt-5 align-items-center">
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <h4 className="text-center mb-4">Parolamı Unuttum</h4>

                                    <div className="form-group">
                                        <label><strong>Mail Adresinizi Girin</strong></label>
                                        <input type="email" onChange={(e) => { setUserName(e.target.value) }} className="form-control" autoComplete="username" />
                                    </div>

                                    {!alertMessage ? null : <div className={`alert ${alertMessage.status===200 ? 'alert-success' : 'alert-danger' }`} role="alert">
                                            {alertMessage.message}</div>
                                            }

                    
                                    <div className="text-center">
                                        <button type="button" onClick={() => sendEmail(userName)} className="btn btn-primary btn-block">Gönder</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword