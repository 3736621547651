import React from "react";

import Cards, { Focused } from 'react-credit-cards';
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import axios from "axios";

import { useHistory } from 'react-router-dom';



function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
}


function limit(val: string, max: string): string {
    if (val.length === 1 && val[0] > max[0]) {
      val = `0${val}`;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  }



export function formatExpirationDate(value: string) {
    const clearValue = clearNumber(value);
  
    if (clearValue.length >= 3) {
        const month = clearValue.slice(0, 2);
        const year = clearValue.slice(2, 4);
        const limitedMonth = limit(month, "12");
        const limitedYear = limit(year, "99");
      return `${limitedMonth}/${limitedYear}`;
    }
  
    return clearValue;
  }

function formatCreditCardNumber(value: string) {
    if (!value) {
        return value;
    }


    const clearValue = clearNumber(value);

    let nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;


    return nextValue.trim();
}
export default function NewCard() {

    const history = useHistory();
    const [focused, setFocused] = React.useState<Focused | undefined>(undefined);
    const [cardData, setCardData] = React.useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        alias: '',
    });

    const [bin, setBin] = React.useState('');
    const [alias, setAlias] = React.useState('');
    const [cardInvalid, setCardInvalid] = React.useState(false);
    const [dateInvalid, setDateInvalid] = React.useState(false);
    const [binResponse, setBinResponse] = React.useState<any>(null);

  React.useEffect(() => {
    if (bin.length == 6) {
      console.log('BIN', bin);
      fetch(`https://cdn.mekatro.tech/bizlealstore/bins/${bin}.json`).then((response) => response.json()).then((response) => {
    console.log(response);  
      setBinResponse(response.bankCode);
      }).catch((err) => {
      });
    } else {
      setBinResponse(null);
    }
  }, [bin]);

  React.useEffect(() => {
      let clearValue = clearNumber(cardData.number).slice(0, 6);
    if(clearValue != bin) {
        setBin(clearValue);
    }
  }, [cardData.number]);

    const handleInputChange = ({ target }: any) => {
        if (target.name === "number") {
            setFocused("number");
            target.value = formatCreditCardNumber(target.value);
            setCardData({
                ...cardData,
                number: target.value,
            });
        } else if (target.name === "expiry") {
            setFocused("expiry");
              target.value = formatExpirationDate(target.value);
            setCardData({
                ...cardData,
                expiry: target.value,
            });
        } else if (target.name === "name") {
            setFocused("name");

            setCardData({
                ...cardData,
                name: target.value,
            });
            
        }
        else if (target.name === "cvc") {
            setFocused("cvc");

            target.value = clearNumber(target.value).slice(0, 3);
            setCardData({
                ...cardData,
                cvc: target.value,
            });
            //   target.value = formatCVC(target.value);
            
        }

        // setState({ [target.name]: target.value });
    };

    React.useEffect(() => {

        if(cardData.expiry.length == 5) {
            
            const thisYear = parseInt(new Date().getFullYear().toString().slice(2));
            const thisMonth = parseInt(new Date().getMonth().toString());

            const expiryYear = parseInt(cardData.expiry.slice(3, 5));
            const expiryMonth = parseInt(cardData.expiry.slice(0, 2));

            if(expiryYear < thisYear) {
                setDateInvalid(true);
                return;
            }

            if(expiryYear == thisYear && expiryMonth < thisMonth) {
                console.log("Invalid");
                setDateInvalid(true);
                return;
            }

            setDateInvalid(false);
            

        } else {
            setDateInvalid(false);
        }

    }, [cardData.expiry]);

    React.useEffect(() => {

        if(cardData.number.length == 19) {

            let value = clearNumber(cardData.number);

            if (/[^0-9-\s]+/.test(value)) return;

          // The Luhn Algorithm. It's so pretty.
          let nCheck = 0; 
          var nDigit = 0; 
          let bEven = false;

          for (let n = value.length - 1; n >= 0; n--) {
            const cDigit = value.charAt(n);
            var nDigit = parseInt(cDigit, 10);

            if (bEven) {
              if ((nDigit *= 2) > 9) nDigit -= 9;
            }

            nCheck += nDigit;
            bEven = !bEven;
          }
          const result = (nCheck % 10) == 0;


          if(result) {
            setCardInvalid(false);
          } else {
            setCardInvalid(true);
          }
        } else {
            setCardInvalid(false);
        }

    }, [cardData.number]);

    const formComplate = cardData.number.length == 19 && cardData.name.length > 0 && cardData.expiry.length == 5 && !cardInvalid && !dateInvalid;

    const submit = (e: any ) => {
        e.preventDefault();

        if(!formComplate) {
            return false;
        }

        const data = {
            "cardAlias": alias,
            "cardHolderName": cardData.name,
            "cardNumber": clearNumber(cardData.number),
            "expireMonth": cardData.expiry.slice(0, 2),
            "expireYear": "20" + cardData.expiry.slice(3, 5),
            "cvc": cardData.cvc,
        }

        axios.post('http://127.0.0.1:5000/user/cards/new', data, { headers:{
            'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
          }})
          .then(function (response: any) {
            // handle success
            history.push('/payment');
            console.log(response.data);

            let content = response.data.threeDSHtmlContent;

            if(content) {
                let n = window.open('', '_blank');
                n?.document.write(Buffer.from(content, 'base64').toString());
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.message);
          })
          .then(function () {
            // always executed
          });

        console.log(data);

        return false;
    }
    const handleInputFocus = ({ target }: any) => {
        setFocused(target.name);
    };

    
    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Yeni Kart Kaydet</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col lg={6} sm={12}>
                                
                                <div className={"customCard"} style={binResponse ? { ["--img-url" as any]: `url('https://cdn.mekatro.tech/bizlealstore/imagestore/cards/${binResponse}.svg')` } : {}}>
                                
                                <Cards
                                    cvc={cardData.cvc}
                                    expiry={cardData.expiry}
                                    focused={focused}
                                    name={cardData.name}
                                    number={cardData.number}
                                    locale={{valid: "Geçerlilik Tarihi"}}
                                    placeholders={{name: "Kart Sahibi"}}
                                    acceptedCards={['visa', 'mastercard']}
                                />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form onSubmit={submit}>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            maxLength={19}
                                            minLength={1}
                                            placeholder="Kartınızın Takma İsmi"
                                            required
                                            onChange={e => setAlias(e.target.value)}
                                        />
                                        {
                                            cardInvalid ? <div className="invalid-feedback">Geçersiz Kart Numarası</div> : null
                                        }
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            type="tel"
                                            name="number"
                                            className="form-control"
                                            maxLength={19}

                                            placeholder="Kart Numaranız"
                                            pattern="[\d| ]{16,22}"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            style={{
                                                backgroundColor: cardInvalid ? "#ffaaaa" : "white",
                                            }}
                                        />
                                        {
                                            cardInvalid ? <div className="invalid-feedback">Geçersiz Kart Numarası</div> : null
                                        }
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Kart Sahibinin İsmi"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                            <Form.Control
                                                type="tel"
                                                name="expiry"
                                                className="form-control"
                                                placeholder="Kart Geçerlilik Tarihi"
                                                pattern="\d\d/\d\d"
                                                maxLength={5}
                                                required
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}

                                            style={{
                                                backgroundColor: dateInvalid ? "#ffaaaa" : "white",
                                            }}
                                            />
                                        
                                    </Form.Group>
                                    <Form.Group>
                                            <Form.Control
                                                type="number"
                                                name="cvc"
                                                className="form-control"
                                                placeholder="Kart cvc Güvenlik Kodu"
                                                pattern="\d\d\d"
                                                maxLength={3}
                                                max={999}
                                                required
                                                onChange={handleInputChange}
                                                onFocus={handleInputFocus}

                                            style={{
                                            }}
                                            />
                                        
                                    </Form.Group>
                                    <Button variant="primary" type="submit" disabled={!formComplate}>
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Col>
        </Row>

    )


}