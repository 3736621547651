import { userConstants } from '../actions/actionTypes';


let user = localStorage.getItem('loginToken');
const initialState = user ? { loggedIn: true, user, message: '' } : {};

export default function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
                message:'loading'
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
                message:'success'
            };
        case userConstants.LOGIN_FAILURE:
            return {loggedIn: false,
                user: 'undefined',
                message:action.error
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}