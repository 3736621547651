import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function creditCardsListReducer(state=initialState.creditCards, action) {//State, Action

    switch (action.type) {
        case actionTypes.GET_CREDIT_CARDS_SUCCESS:
            return action.payload
        
        default:
            return state;
    }
}