import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap'
import Cards from 'react-credit-cards';

import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import * as categoryActions from "../../../redux/actions/userActions"
import 'react-credit-cards/es/styles-compiled.css';
import axios from 'axios';


interface CardData {
    _id:             string;
    cardToken:       string;
    aliasName:       string;
    redactedNumbers: string;
    expireDate:      string;
    cardAssociation: string;
    cardBankCode:    number;
    cardBankName:    string;
}

function Payment(props: any) {

    const history = useHistory();

    const [cards, setCards] = React.useState<CardData[]>([]);

    React.useEffect(() => {
        if (localStorage.getItem('loginToken') === null) {
          history.push('./login');
          return;
        }
        else {
        //   console.log('Payment Page User Token:',localStorage.getItem('loginToken'))
        //   axios.get('https://liveapi.appac.io/user/cards',{headers:{
        //     'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
        //   }})
        //   .then(function (response) {
        //     // handle success
        //     console.log(response.data);
        //     setCards(response.data)
        //   })
        //   .catch(function (error) {
        //     // handle error
        //     console.log(error.response.data.message);
        //   })
        //   .then(function () {
        //     // always executed
        //   });
        props.actions.getCreditCards()
   
        }
      }, [])
    return (
        <>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Kayıtlı Kartlarım</Card.Title>
                            <h5> Seçili Kart: {props.currentCard.cardName}</h5>
                            <h5> Toplam kart Kart: {props.creditCards.length}</h5>

                        </Card.Header>
                        <Card.Body>
                            <Row>
                                {
                                    props.creditCards.map((card: { _id: string | undefined; cardBankCode: any; expireDate: string | number; cardAssociation: string; aliasName: string; redactedNumbers: string | number; }) => {
                                        return (
                                            <Col lg={6} xl={4} sm={12} xs={12} md={6} className={"mb-4"} id={card._id}>
                                                <div className={"customCard clickable"} style={{ ["--img-url" as any]: `url('https://cdn.mekatro.tech/bizlealstore/imagestore/cards/${card.cardBankCode}.svg')` }}>
                                                    <Cards
                                                        cvc={"000"}
                                                        expiry={card.expireDate}
                                                        preview={true}
                                                        issuer={card.cardAssociation === "VISA" ? "visa" : "mastercard"}
                                                        name={card.aliasName}
                                                        number={card.redactedNumbers}
                                                    />
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                                <Col lg={6} xl={4} sm={12} xs={12} md={6} className={"mb-4"}>
                                <div className={"customCardNew"} onClick={() => history.push("/newPayment")}>

                                </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

function mapStateToProps(state: any){
    return {
        currentCard: state.changeCardReducer,
        creditCards: state.creditCardsListReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions:{
            getCreditCards:bindActionCreators(categoryActions.getCreditCards, dispatch)
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Payment)

