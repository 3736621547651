import { combineReducers } from "redux";
import changeCardReducer from "./changeCardReducer";
import creditCardsListReducer from "./creditCardsListReducer";
import authenticationReducer from "./authenticationReducer";
import alertReducer from './alertReducer';

const rootReducer = combineReducers({
    changeCardReducer,
    creditCardsListReducer,
    authenticationReducer,
    alertReducer,
})

export default rootReducer