import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Register from './pages/loginPages/register';
import ForgotPassword from './pages/loginPages/forgotPassword';
import RegisterationSuccess from './pages/loginPages/registerationSuccess'

import { useHistory } from 'react-router-dom';

import Login from './pages/loginPages/login';
import MainPage from './component/mainPage';
import Eroor400 from './pages/400';
import Eroor403 from './pages/403';
import Eroor404 from './pages/404';
import Eroor500 from './pages/500';
import Eroor503 from './pages/503';
import axios from 'axios';



export default function Markup() {


    const history = useHistory();

    React.useEffect(() => {
        console.log('Payment Page User Token:', localStorage.getItem('loginToken'))
        axios.get('https://liveapi.appac.io/user/account/me', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
            }
        })
            .then(function (response) {
                // handle success
                console.log('TEST1');
                localStorage.getItem('loginToken')
                console.log(response.data);

            })
            .catch(function (error) {
                // handle error
                console.log('TEST2');
                console.log(localStorage.getItem('loginToken'));
                console.log(error.response.data.name);

                console.log(error.response.data.message);

                switch (error.response.data.name) {
                    case 'TokenExpiredError':
                        console.log('TEST3');

                        localStorage.removeItem('loginToken')
                        return (
                        <Route path='/login' component={Login} />
                        )
                        break;
                    case 'JsonWebTokenError':
                        console.log('TES4');
                        localStorage.removeItem('loginToken')
                        // history.push("/login");


                        break;
                    default:
                        break;
                }
            })
            .then(function () {

                // always executed
            });
    }, [history]);
    


        // componentWillMount() {
        //     this.setState({ height: window.innerHeight + 30 + 'px' });
        // }
        return (
            <>
                {/* <BrowserRouter basename={'/appac'}> */}

                <BrowserRouter>
                    <Switch>
                        <Route path='/login' component={Login} />
                        <Route path='/register' component={Register} />
                        <Route path='/forgotpassword' component={ForgotPassword} />
                        <Route path='/registeration-success' component={RegisterationSuccess} />

                        <Route path='/400' component={Eroor400} />
                        <Route path='/403' component={Eroor403} />
                        <Route path='/404' component={Eroor404} />
                        <Route path='/500' component={Eroor500} />
                        <Route path='/503' component={Eroor503} />
                        <Route path='/' component={MainPage} />
                        <Route component={Eroor404} />

     
                        {/* <Route path='/' exact component={localStorage.getItem('loginToken') ===} /> */}
                    </Switch>


                </BrowserRouter>
            </>
            
        );
    
}
