import React from 'react'

import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';

import NavHeader from '../layout/navHeader';
import Header from '../layout/header';
import Sidebar from '../layout/sidebar';
// import Footer from '../layout/footer';
import Homepage from '../pages/homepage';
import Homepage2 from '../pages/homepage2';
import Profile from '../pages/app-profile';


import EmailInbox from '../pages/email-inbox';
import UiAlert from '../component/ui/alert';
import UiAccordion from '../component/ui/accordion';
import UiBadge from '../component/ui/badge';
import UiBreadcrumb from '../component/ui/breadcrumb';
import UiButtonGroup from '../component/ui/button-group';
import UiButtons from '../component/ui/buttons';
import UiCards from '../component/ui/cards';
import UiCarousel from '../component/ui/carousel';
import UiDropdowns from '../component/ui/dropdowns';
import UiFigures from '../component/ui/figures';
import UiImages from '../component/ui/images';
import UiInputGroup from '../component/form/input-group';
import UiJumbotron from '../component/ui/jumbotron';
import UiListGroup from '../component/ui/list-group';
import UiModal from '../component/ui/modal';
import UiNavbar from '../component/ui/navbar';
import UiNavs from '../component/ui/navs';
import UiOverlay from '../component/ui/overlays';
import UiPagination from '../component/ui/pagination';
import UiPopover from '../component/ui/popovers';
import UiProgress from '../component/ui/progress';
import UiSpinner from '../component/ui/spinners';
import UiTabs from '../component/ui/tabs';
import UiToasts from '../component/ui/toasts';
import UiTooltips from '../component/ui/tooltips';
import UiForms from '../component/form/forms';
import UiTable from '../component/table/table';
import PhoneVerification from '../pages/loginPages/phoneVerification'

import ChartChartjs from '../component/charts/chartjs';
import ChartChartist from '../component/charts/chartist';
import ChartSparkline from '../component/charts/sparkline';
import ChartPeity from '../component/charts/peity';
import CircularProgress from '../component/plugin/circular';
import SlickSlider from '../component/plugin/slick';
import AppCalendar from '../pages/app-calendar';
import Register from '../pages/loginPages/register';
import ForgotPassword from '../pages/loginPages/forgotPassword';
import Login from '../pages/loginPages/login';
import Eroor400 from '../pages/400';
import Eroor403 from '../pages/403';
import Eroor404 from '../pages/404';
import Eroor500 from '../pages/500';
import Eroor503 from '../pages/503';
import ApiKeys from '../pages/userPages/apiKeys';
import Bills from '../pages/userPages/bills';
import Credits from '../pages/userPages/credits';
import Payment from '../pages/userPages/payment';
import Packager from '../pages/userPages/profile';

import UserProfile from '../pages/userPages/profile';
import Subscriptions from '../pages/userPages/subscriptions';
import NewCard from '../pages/userPages/newCard';
import ApiPackages from '../pages/userPages/apiPackages';

export default function MainPage() {

  const [height, setHeight] = React.useState(window.innerHeight + 30 + 'px')

  React.useEffect(() => {
    return () => {
      setHeight(window.innerHeight + 30 + 'px')
    }
  }, [])

  return (
    <div id="main-wrapper">
      <NavHeader />
      <Header />
      <Sidebar />
      <div className="content-body" style={{ "minHeight": `${height}` }}>
        <div className="container-fluid">
          <Switch>

            {/* <R exactoute path='/' exact component={Homepage} /> */}
            <Route exact path='/index2' component={Homepage2} />
            <Route exact path='/app-profile' component={Profile} />
            <Route exact path='/email-inbox' component={EmailInbox} />
            <Route exact path='/alert' component={UiAlert} />
            <Route exact path='/accordion' component={UiAccordion} />
            <Route exact path='/badge' component={UiBadge} />
            <Route exact path='/breadcrumb' component={UiBreadcrumb} />
            <Route exact path='/button-group' component={UiButtonGroup} />
            <Route exact path='/buttons' component={UiButtons} />
            <Route exact path='/cards' component={UiCards} />
            <Route exact path='/carousel' component={UiCarousel} />
            <Route exact path='/dropdowns' component={UiDropdowns} />
            <Route exact path='/figures' component={UiFigures} />
            <Route exact path='/images' component={UiImages} />
            <Route exact path='/input-group' component={UiInputGroup} />
            <Route exact path='/jumbotron' component={UiJumbotron} />
            <Route exact path='/list-group' component={UiListGroup} />
            <Route exact path='/modal' component={UiModal} />
            <Route exact path='/navbar' component={UiNavbar} />
            <Route exact path='/navs' component={UiNavs} />
            <Route exact path='/overlays' component={UiOverlay} />
            <Route exact path='/pagination' component={UiPagination} />
            <Route exact path='/popovers' component={UiPopover} />
            <Route exact path='/progress' component={UiProgress} />
            <Route exact path='/spinners' component={UiSpinner} />
            <Route exact path='/tabs' component={UiTabs} />
            <Route exact path='/toasts' component={UiToasts} />
            <Route exact path='/tooltips' component={UiTooltips} />
            <Route exact path='/forms' component={UiForms} />
            <Route exact path='/table' component={UiTable} />
            <Route exact path='/chartjs' component={ChartChartjs} />
            <Route exact path='/chartist' component={ChartChartist} />
            <Route exact path='/sparkline' component={ChartSparkline} />
            <Route exact path='/peity' component={ChartPeity} />
            <Route exact path='/circular' component={CircularProgress} />
            <Route exact path='/slick' component={SlickSlider} />
            <Route exact path='/calendar' component={AppCalendar} />
            {/* <Route exact path='/register' component={Register} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgotpassword' component={ForgotPassword} />
            <Route exact path='/400' component={Eroor400} />
            <Route exact path='/403' component={Eroor403} />
            <Route exact path='/404' component={Eroor404} />
            <Route exact path='/500' component={Eroor500} />
            <Route exact path='/503' component={Eroor503} /> */}
            <Route exact path='/api-keys' component={ApiKeys} />
            <Route exact path='/bills' component={Bills} />
            <Route exact path='/credits' component={Credits} />
            <Route exact path='/payment' component={Payment} />
            <Route exact path='/newPayment' component={NewCard} />
            <Route exact path='/profile' component={UserProfile} />
            <Route exact path='/subscriptions' component={Subscriptions} />
            <Route exact path='/apipackages' component={ApiPackages} />
            <Route path='/phone-verification' component={PhoneVerification} />

            <Route component={Eroor404} />

          </Switch>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  )
}
