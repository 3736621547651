import React, { } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, useAsyncDebounce, usePagination } from "react-table";

import { Link } from 'react-router-dom';
import { Row, Col, Card, Modal, Table, Button, ButtonGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';

import { matchSorter } from 'match-sorter'
function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}
interface NewApi {
    keyId: string;
    key: string;
}
interface ApiKey {
    _id: string;
    pub: string;
}
function ApiKeys() {

    const [deleteModalShow, setDeleteModalShow] = React.useState(false);
    const [createApiModalShow, setCreateApiModalShow] = React.useState(false);
    const [newApi, setNewApi] = React.useState<NewApi | null>(null);
    const [selectedApiId, setSelectedApiId] = React.useState('')
    const createApi = () => {
        axios.get('https://liveapi.appac.io/user/keys/new', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
            }
        })
            .then((response: any) => {
                // handle success
                console.log(response.data);
                setNewApi(response.data);
                getApiKeys();
                setCreateApiModalShow(true)
            })
            .catch((error: any) => {
                // handle error
                setNewApi(error.response.data.message)
                setCreateApiModalShow(true)

            })
    }
    const history = useHistory()
    const axios = require('axios');
    const [data, setData] = React.useState<ApiKey[]>([])

    const getApiKeys = () => {

        axios.get('https://liveapi.appac.io/user/keys', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
            }
        })
            .then(function (response: any) {
                // handle success
                console.log(response.data);
                setData(response.data.keys);
            })
            .catch(function (error: any) {
                // handle error
                console.log(error.response.data.message);
            })
    };

    React.useEffect(() => {
        if (localStorage.getItem('loginToken') === null) {
            history.push('./login');
            return;
        }
        getApiKeys();
    }, [])

    const deleteApi = (key: string) => {
        console.log('Geet invoice', key);

        axios.delete(`https://liveapi.appac.io/user/keys/${key}`, {

            headers: {
                'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
            }
        })
            .then(function (response: any) {
                // handle success
                setData(response.data.keys)
                setDeleteModalShow(false);
                console.log(response.data.key);

            })
        return false;
    }

    return (
        <>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>API Anahtarlarım</Card.Title>
                            <button type="button" onClick={() => createApi()} className="btn btn-success">API Anahtarı Oluştur</button>

                        </Card.Header>
                        <Card.Body>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>Anahtar Numarası</th>
                                        <th>İşlemler</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, index: number) => (
                                        <tr key={index}>
                                            <td>{row._id}</td>
                                            <td>
                                            <ButtonGroup aria-label="API islemleri">
                                            <Button variant="primary" onClick={() => { download(`appac_live_${row._id}_pub.p8`, row.pub); }}>Public Key İndir</Button>
                                            <Button variant="danger" onClick={() => { setSelectedApiId(row._id); setDeleteModalShow(true); }}>Sil</Button>
                                            </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal
                size="sm"
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Uyarı
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><b><i className="fa fa-lock"></i> &nbsp;:&nbsp;

                    {selectedApiId}</b>
                    <p>Yukarıdaki API Anahtarını silmek istediğinize emin misiniz ?</p></Modal.Body>
                <div className="modal-footer"><button onClick={() => setDeleteModalShow(false)} type="button" className="btn btn-secondary">İptal</button>
                    <button type="button" onClick={() => deleteApi(selectedApiId)} className="btn btn-danger">Sil</button></div>
            </Modal>

            <Modal
                size="lg"
                keyboard={false}
                hideOnBackdropClick={false}
                show={createApiModalShow}
                // onHide={() => setCreateApiModalShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {newApi === null ?
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Başarısız
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{newApi}</p></Modal.Body>

                        <div className="modal-footer"><button onClick={() => setCreateApiModalShow(false)} type="button" className="btn btn-secondary">Tamam</button>
                        </div>
                    </> :
                    <>
                        <Modal.Header>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Başarılı
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body><b><i className="fa fa-lock"></i> &nbsp;:&nbsp;

                            {newApi?.keyId}</b>
                            <p>Yeni API Anahtarınız başarıyla oluşturuldu. Lütfen API Anahtarınızı indirin ve saklayın. Eğer api anahtarınızı kaybederseniz silip tekrardan oluşturmanız gerekmektedir. </p></Modal.Body>
                            <textarea style={{height: 300, margin: 8, padding: 8}}>
                                {newApi.key}
                            </textarea>
                        <div className="modal-footer">
                            <button type="button" onClick={() => {
                                download(`appac_live_${newApi?.keyId}.p8`, newApi!.key);setCreateApiModalShow(false)
                            }} className="btn btn-success">İndir</button></div>
                    </>}
            </Modal>

        </>
    )
}

export default ApiKeys;